

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Quattrocento Sans',
    monospace;
}

body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* App.css */
#root {
  text-align: center;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.meritums-yellow {
  background-color: rgba(0, 50, 100, 0);
}