.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    position: absolute;
    width: 100%;
    height: 20%;
    left: 0px;
    top: 0px;
  
    background: #023E8A;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .label{
    font-family: 'Quattrocento Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    /* or 30% */
    align-items: center;
    letter-spacing: 0.15px;
  
    color: #90E0EF;
  }
  
  .plain{
  
    font-family: 'Quattrocento Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 4vh;
    line-height: 12px;
    /* or 30% */
    align-items: center;
    color: white;
  }
  
  
  .content{
      top:'20vh';
      background: #FFFFFF;
  }
  
  .sidedrawer{
    top:'20vh';
    background: #FFFFFF;
  }

  .sidebar{
    overflow-x: hidden;
  }

  ol {
    counter-reset: item;
  }

  ol > ul {
    list-style-type: square;
  }

  li {
    color: #666666;
  }
  ol > li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }

